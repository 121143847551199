@import url('https://fonts.googleapis.com/css?family=Blinker');

* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  font-family: 'Blinker', monospace, Arial, Helvetica, sans-serif;
}

body {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

#root {
  min-height: 100%;
  height: 100%;
  min-width: 350px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
